import React from 'react'
import { createRoot } from 'react-dom/client'
import 'regenerator-runtime'
import * as serviceWorker from './serviceWorker'
import { fetchConfig } from './utils/config'

const App = React.lazy(async () => await import('./App'))

const init = async () => {
    const config = await fetchConfig()

    const container = document.getElementById('root')
    if (container) {
        const root = createRoot(container)
        root.render(
            <React.Suspense fallback={<></>}>
                <App config={config} />
            </React.Suspense>,
        )
    }
}

init().catch(console.error)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
